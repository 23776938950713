import {
  Box,
  Button,
  Input,
  Modal,
  Paper,
  Popover,
  IconButton,
  Tooltip,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import EmailIcon from "@mui/icons-material/Email";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import "react-toastify/dist/ReactToastify.css";
import circleloader from "../../Assets/Header/newLoaderBlue.mp4";
import sendicon from "../../Assets/OuterImg/sendicon.svg";
import share from "../../Assets/OuterImg/shareActive.svg";
import shareInactive from "../../Assets/OuterImg/shareInactive.svg";
import ChatIcon from "../../Assets/OuterImg/commentsIconActive.svg";
import ChatIconInActive from "../../Assets/OuterImg/CommentIconInActive.svg";
import BGImage from "../../Assets/mainPage/chatMessageBg.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MicIcon from "@mui/icons-material/Mic";
import DownloadIcon from "@mui/icons-material/Download";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HelpIcon from "@mui/icons-material/Help";
import * as Styles from "../../Common/Styles.js";
import { useGetBatchList, useGetUserProfile } from "../../Hooks/ProfileHooks";
import batchImg from "../../Assets/MessageBoxImg/batchImg.svg";
import batchGreyImg from "../../Assets/MessageBoxImg/batchGreyImg.svg";
import { useGetWallet, useGetWalletHistroy } from "../../Hooks/wallet.hooks.js";
import { DotPulse, Ring, Waveform } from "@uiball/loaders";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  useCreateChatMessages,
  useGetUsersChatMessages,
  useShareChatMessages,
  useGetNewChatList,
  useCreateNewChat,
  useGetFollowUpMessages,
} from "../../Hooks/chatHooks";
import { useQueryClient } from "@tanstack/react-query";
import CommentBox from "./CommentBox";
import Typewriter from "typewriter-effect";
import axios from "axios";
import Markdown from "react-markdown";
import {
  useGetUsersSinglePdfs,
  useGetUsersPdfs,
  useDeleteChat,
} from "../../Hooks/FileUpload/fileHooks.js";
import cookie from "js-cookie";
import logoutlite from "../../Assets/ProfileImg/logout.png";
import logout from "../../Assets/OuterImg/logout.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HeaderPhone from "../HeaderUi/HeaderPhone.jsx";
import Header from "../HeaderUi/Header.jsx";
import chaticon from "../../Assets/mainPage/chaticon.svg";
import trash from "../../Assets/ProfileImg/trash.png";
import trashTheme from "../../Assets/MessageBoxImg/trashTheme.png";
import pageicon from "../../Assets/mainPage/pageicon.svg";
import { useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";

import { GetTokenFunc } from "../../utils/GetToken.js";
import AnimatedText from "./AnimatedText";
import renderToolbar from "../CommonToast/renderToolbar.jsx";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import {
  useCreateBookmark,
  useGetBookmark,
  useSendPdfInMail,
} from "../../Hooks/CommentHooks.js";
import ImportExportIcon from "@mui/icons-material/ImportExport";

const MessageBox = ({
  chatData,
  selectPdf,
  selectMultiplePdf,
  isOpenPdf,
  batchName,
  // setPageNumber,
  routeId,
  clearInput,
  value,
  // setShowPdf,
  // setIsPdfVisible,
  // newChatListRefetch,
}) => {
  // Pdf Show time
  const [isActiveToPush, setIsActiveToPush] = useState(false);
  const [gridSize, setGridSize] = useState(false);
  const [helpModel, setHelpModal] = useState(false);
  const [bookmarkedItems, setBookmarkedItems] = useState({});
  const [isChatIconHovered, setChatIconHovered] = useState(false);
  const [isShareIconHovered, setShareIconHovered] = useState(false);
  const LocationData = useLocation();
  const [todoList, setTodoList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const { mutate } = useDeleteChat();
  const [animateLastMessage, setAnimateLastMessage] = useState(false);
  const [lastMessageId, setLastMessageId] = useState(null);
  const [clearText, setclearText] = useState(false);
  const [open, setOpen] = useState(false);
  const [singleFileOpen, setSingleFileOpen] = useState(false);
  const getFilePluginInstance = getFilePlugin();
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });
  const [pdfUrl, setPdfUrl] = useState();
  const [isSorted, setIsSorted] = useState(false);
  const [openMail, setOpenMail] = useState(false);
  const { mutate: CreateBookmark } = useCreateBookmark();
  const { data: bookmarksData, refetch } = useGetBookmark();

  const handleOpenMail = () => setOpenMail(true);
  const handleCloseMail = () => setOpenMail(false);
  const handleCloseSingle = (e) => {
    setSingleFileOpen(false);
    setChatModal(false);
  };
  const handleChatIconHover = () => {
    setChatIconHovered(true);
    setShareIconHovered(false);
  };
  const handleShareIconHover = () => {
    setChatIconHovered(false);
    setShareIconHovered(true);
  };
  const handleMouseLeaveHoevr = () => {
    setChatIconHovered(false);
    setShareIconHovered(false);
  };

  const navigate = useNavigate();
  // const id = chatData?._id;

  const { id } = useParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showPdf, setShowPdf] = useState();
  const [isPdfVisible, setIsPdfVisible] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const navStyle = {
    listStyle: "none",
    padding: 0,
    margin: 0,
    position: "absolute",
    top: "5.5rem",
    right: isMenuOpen ? "0" : "100%",
    backgroundColor: "rgba(244, 245, 246, 1)",
    width: "100%",
    transition: "right 1s",
    height: "85vh",
    zIndex: 10000,
  };
  const openSourceLink = (fileData, page) => {
    setIsPdfVisible(true);
    setShowPdf(fileData);
    setPageNumber(page ?? 1);
    handleChatModalopen();
  };
  //   Message box
  const [chatId, setChatId] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const { mutate: createChatMessage, isLoading: chatMessageLoading } =
    useCreateChatMessages(id);
  const queryClient = useQueryClient();
  const { data: ChatMessages, refetch: ChatMessagesRefecth } =
    useGetUsersChatMessages(id);

  const { mutate: shareChatMessage, isLoading: shareChatMessageLoading } =
    useShareChatMessages(id);

  const Typing_Changes = (e) => {
    const data = e.target.value;
    setNewMessage(data);
  };

  useEffect(() => {
    ChatMessagesRefecth(id);
  }, [chatId]);

  // hover animation
  const [email, setEmail] = useState();
  const { mutate: sendPdf, isLoading: MailLoder } = useSendPdfInMail();

  const sendPdfMail = async () => {
    const data = {
      pdfUrl: pdfUrl,
      email: email,
    };

    await sendPdf(data, {
      onSuccess: () => {
        toast.success(`Sended Pdf Successfully`);
        handleCloseMail();
        setEmail("");
      },
    });
  };

  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredDownload, setIsHoveredDownload] = useState(false);
  const handleMouseEnterDownload = () => {
    setIsHoveredDownload(true);
  };
  const handleMouseLeaveDownload = () => {
    setIsHoveredDownload(false);
  };
  const fileNameOccurrences = {};
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [messages, setMessages] = useState([]);
  let questionsArray = [];
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  useEffect(() => {
    if (messages?.length > 0) {
      send_Massages(messages[0]);
    }
  }, [messages]);
  const handleSelectBatch = async (index) => {
    setSelectedBatch(index);
    setCurrentQuestionIndex(0);
    setMessages(batchList?.data?.data[index]?.questions?.map((item) => item));
  };
  // for input Box Glow on Click

  const handleBookmark = async (fileId, fileName, fileUrl, e) => {
    e.stopPropagation();

    const data = {
      fileId,
      fileName,
      fileUrl,
    };

    // Toggle the bookmark state for this specific item
    setBookmarkedItems((prev) => ({
      ...prev,
      [fileId]: prev[fileId],
    }));

    await CreateBookmark(data, {
      onSuccess: () => {
        toast.success("Bookmark updated successfully");
        refetch();
      },
      onError: () => {
        // Revert the state if the API call fails
        setBookmarkedItems((prev) => ({
          ...prev,
          [fileId]: prev[fileId],
        }));
        toast.error("Failed to update bookmark");
      },
    });
  };

  const [isInputFocused, setIsInputFocused] = useState(false);
  console.log("isInputFocused", isInputFocused);
  const handleInputFocus = () => {
    setIsInputFocused(true);
  };
  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  function isTimeInRange() {
    // Set the timezone to UTC+05:30 (Indian Standard Time)
    const timezoneOffset = 5.5 * 60; // Offset in minutes (UTC+05:30)
    const now = new Date();

    // Get current UTC time in minutes
    const currentUTC = now.getUTCHours() * 60 + now.getUTCMinutes();

    // Calculate 9 AM and 6 PM in UTC+05:30
    const nineAM = 9 * 60 + timezoneOffset;
    const sixPM = 18 * 60 + timezoneOffset;

    // Check if current time is between 9 AM and 6 PM
    if (currentUTC <= nineAM && currentUTC < sixPM) {
      return true;
    } else {
      return false;
    }
  }
  const {
    data: walletData,
    isLoading: walletLoading,
    error: walletError,
    refetch: walletDataRefetch,
  } = useGetWallet();

  // send messages
  const send_Massages = (message = newMessage) => {
    // if (!isTimeInRange()) {
    //   toast.info('Chat is only available between 9 AM and 6 PM.');
    //   return;
    // }
    if (message === "") return;
    if (walletData?.data?.at(0)?.balance === 0) {
      toast.error("Insufficient Balance");
      return;
    }
    console.log("works till here 1");
    queryClient.setQueryData(["user-chats", id], (data) => {
      const oldMessages = data.data.data;
      const newMessages = [
        ...oldMessages,
        {
          message: message,
          role: "user",
          createdAt: new Date(),
        },
      ];
      return {
        data: {
          data: newMessages,
        },
      };
    });

    setNewMessage("");
    console.log("well message", message, newMessage);
    createChatMessage(
      { id: id, history: [], question: message },
      {
        onSuccess: async (res) => {
          setIsActiveToPush(false);
          console.log("sdhfgsydf", res, message);
          // questionsArray.pop(message);
          // const removeItem = (itemToRemove) => {
          //   questionsArray = questionsArray.filter(
          //     (question) => question !== itemToRemove
          //   );
          // };
          // removeItem(message);
          setLastMessageId(res?.data?.data?.botMessage?._id.toString());
          if (questionsArray.length > 0) {
            handleSelectBatch(0);
          }

          await ChatMessagesRefecth();
          messages.shift();
          console.log("herezzz 1");

          if (ChatMessages?.data?.data?.length === 2) {
            console.log("herezzz 2");
            await axios
              .get(`api/v1/chat-session/generate-chat-name/${routeId}`, {
                headers: {
                  Authorization: `Bearer ${GetTokenFunc()}`,
                },
              })
              .then((res) => {
                console.log("res herezzz", res);
              });
            newChatListRefetch();
          }
          setMessages([...messages]);
          setNewMessage("");
          setIsInputFocused(false);
          followUpMessagesRefecth();
          setAnimateLastMessage(true);
          // Send next question if there's a selected batch
          // if (selectedBatch !== null) {
          //   console.log('Current Question Index:', currentQuestionIndex); // Debug log
          //   sendNextQuestion(selectedBatch, currentQuestionIndex);
          // }
        },
        onError: (err) => {
          toast.error("Error Sending Message");
        },
      }
    );
  };
  const handleTagClick = (tagText) => {
    setNewMessage(tagText);
    send_Massages(tagText);
  };
  // speech
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const handleSpeechRecognition = () => {
    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      SpeechRecognition.startListening();
    }
  };
  useEffect(() => {
    setNewMessage(transcript);
  }, [transcript]);

  useEffect(() => {
    setIsListening(listening);
  }, [listening]);

  // profile
  const {
    data: profileData,
    isLoading: profileLoading,
    error: profileError,
    refetch: profileDataRefetch,
  } = useGetUserProfile();

  // download chat
  const downloadChat = () => {
    var chatData = ChatMessages?.data?.data;
    var chatName = profileData?.data?.data?.fullName;
    if (chatData && chatData.length > 0) {
      var csvContent =
        "Time,Role,Name,Message\n" +
        chatData
          .map((item, i) => {
            const formattedDate = new Date(item.createdAt).toLocaleString();
            return `${item.role === "user" ? "User" : "Bot"},${formattedDate},${
              item.role === "user" ? chatName : "Bot"
            },"${item.message}"`;
          })
          .join("\n");

      const element = document.createElement("a");
      const file = new Blob([csvContent], { type: "text/txt" });
      element.href = URL.createObjectURL(file);
      element.download = "chat.txt";
      document.body.appendChild(element);
      element.click();
    } else {
      toast.error("Chat is empty. Cannot download.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  //

  const {
    data: followUpMessages,
    isLoading: followUpMessagesLoading,
    refetch: followUpMessagesRefecth,
    error: followUpMessagesError,
  } = useGetFollowUpMessages(id);
  // Comment Section
  const [ChatModal, setChatModal] = useState(false);
  const [chatInput, setChatInput] = useState(true);
  const handleChatModalopen = () => {
    setChatModal(true);
  };

  // edit pdf file name
  const [editing, setEditing] = useState(false);
  const [newBatchName, setNewBatchName] = useState(
    chatData?.batchName || chatData?.fileData?.at(0)?.fileName
  );

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSaveClick = () => {
    // onSave(newBatchName);
    setEditing(false);
  };
  const messageBoxRef = useRef();

  useEffect(() => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  }, [ChatMessages]);

  const handleInputChange = (e) => {
    setNewBatchName(e.target.value);
  };

  const [loading, setLoading] = useState(false);
  const handleShareClick = async () => {
    setLoading(true);
    const confirm = window.confirm("Are you sure you want to share this chat?");
    if (!confirm) {
      setLoading(false);
      return;
    }
    shareChatMessage(id, {
      onSuccess: (res) => {
        toast.success("Chat Shared Successfully");
        setLoading(false);
      },
      onError: (err) => {
        toast.error("Error Sharing Chat");
        setLoading(false);
      },
    });
  };
  // download text file

  const inputRef = useRef(null);
  const [isTrashIconHovered, setTrshIconHovered] = useState(false);
  const handleDeleteItem = (id) => {
    mutate(
      { id },
      {
        onSuccess: () => {
          toast.success("Delete Successfully");
          newChatListRefetch();
          const updatedChatList = newChatList?.data?.data.filter(
            (item) => item._id !== id
          );
          if (updatedChatList.length === 0) {
            navigate("/mainchat/:id");
          } else {
            const deletedIndex = newChatList?.data?.data.findIndex(
              (item) => item._id === id
            );

            if (deletedIndex !== -1) {
              if (deletedIndex === newChatList?.data?.data.length - 1) {
                const previousId =
                  updatedChatList[updatedChatList.length - 1]?._id;
                navigate(`/mainchat/${previousId}`);
              } else {
                const nextId = updatedChatList[deletedIndex]?._id;
                navigate(`/mainchat/${nextId}`);
              }
            }
          }
        },
        onError: (error) => {
          toast.error("Failed to delete: " + error.message);
        },
      }
    );
  };
  const handleTrashIconHover = (itemId) => {
    setTrshIconHovered(itemId);
  };
  // Effect to clear the input field when triggered
  useEffect(() => {
    if (clearInput === true || clearInput === false) {
      setNewMessage("");
    }
  }, [clearInput]);
  const {
    data: fileUploadList,
    isLoading: fileUploadListLoading,
    isError: fileUploadListError,
    refetch: refetchFileUploadList,
  } = useGetUsersPdfs();
  const [expanded, setExpanded] = useState(0);
  const [activeBox, setActiveBox] = useState(null);
  const handleBoxClick = (item) => {
    setActiveBox(item);
  };
  const {
    data: fileUploadListSingle,
    isLoading: fileUploadListSingleLoading,
    refetch: refetchFileUploadListSingle,
  } = useGetUsersSinglePdfs();
  const {
    data: batchList,
    isLoading: batchListLoading,
    error: batchListError,
    refetch: batchListRefetch,
  } = useGetBatchList();
  const {
    data: newChatList,
    isLoading: newChatListLoading,
    error: newChatListError,
    refetch: newChatListRefetch,
  } = useGetNewChatList();

  const {
    mutate: newChatMutate,
    isLoading: newChatLoading,
    isError: newChatError,
  } = useCreateNewChat();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue: newChatSetValue,
    formState: { errors },
  } = useForm({});
  // const newChatNameSubmit = handleSubmit((data) => {
  //   console.log('newChatNameSubmit', data);
  //   newChatMutate(data, {
  //     onSuccess: (response) => {
  //       toast.success('Chat Created Successfully');
  //       handleCloseSingle();
  //       newChatListRefetch();
  //       reset();
  //     },
  //     onError: (error) => {
  //       console.log('error', error);
  //     },
  //   });
  // });
  const newChatNameSubmit = handleSubmit((data) => {
    const chatData = {
      ...data,
      name: data.name || "New Chat",
    };
    newChatMutate(chatData, {
      onSuccess: async (response) => {
        toast.success("Chat Created Successfully");
        handleCloseSingle();
        await newChatListRefetch();
        reset();
        navigate("/mainchat/" + response?.data?.data?._id);
        console.log("responseeee", response);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  });
  const clearInputBox = () => {
    if (clearText === true) {
      setclearText(false);
    } else {
      setclearText(true);
    }
  };
  const ChatMessagesClick = (id) => {
    navigate(`/mainchat/${id}`);
  };
  const [activeIndex, setActiveIndex] = useState(null);
  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const scrollBoxRef = useRef(null);
  const scrollForward = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({ left: 200, behavior: "smooth" }); // Adjust the scroll amount as needed
    }
  };
  // copy messages to clipboard
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const copyToClipboard = (text, messageId) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Message copied to clipboard!");
      setCopiedMessageId(messageId);
      setTimeout(() => setCopiedMessageId(null), 2000); // Reset after 2 seconds
    });
  };
  const extractYear = (url) => {
    const match = url?.match(/\/(\d{4})$/); // Extracts the last 4 digits (year)
    return match ? parseInt(match[1], 10) : 0; // Convert to number, default to 0 if no match
  };

  // Toggle sorting state
  const toggleSorting = () => {
    setIsSorted((prev) => !prev);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        display: {
          xl: "none",
          lg: "none",
          md: "none",
          sm: "flex",
          xs: "flex",
        },
        flexDirection: "column",
        overflow: "hidden",
        width: "100%",
        background:
          "linear-gradient(219deg, #EBEBEF -11.5%, #F9F9FA 31.72%, rgba(255, 255, 255, 0.90) 32.22%, rgba(240, 241, 241, 0.02) 40.58%, rgba(245, 246, 246, 0.14) 57.06%, #D7D7D7 115.43%)",
      }}
    >
      <HeaderPhone />
      <Box
        sx={{
          flex: gridSize ? "1 1 83%" : " 1 1 60%",
          transition: "flex-basis 0.5s linear",
          mt: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "95%",
            mx: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <Box
              sx={{ ...Styles.newChat, width: "auto", height: "auto", p: 0.5 }}
              onClick={() => newChatNameSubmit()}
            >
              <Box>+</Box>
              {/* <Modal
                open={singleFileOpen}
                onClose={() => {
                  setSingleFileOpen(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 9999 }}
              >
                <form onSubmit={newChatNameSubmit}>
                  <Box sx={{ ...modalStyle, height: 'auto' }}>
                    <CloseIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCloseSingle();
                      }}
                      sx={{
                        cursor: 'pointer',
                      }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Input
                        id="standard-basic"
                        variant="outlined"
                        disableUnderline
                        {...register('name')}
                        placeholder="chat name"
                        sx={{
                          width: '40%',
                          height: '22px',
                          background: '#F4F5F6',
                          borderRadius: '5px',
                          fontSize: '12px',
                          color: '#445FD2',
                          p: 2,
                          border: 'none',
                          '&::placeholder': {
                            color: '#445FD2',
                          },
                        }}
                      />
                    </Box>
                    <Button
                      type="submit"
                      sx={{ ...signInBtn }}
                      // disabled={fileSingleUploadLoading ? true : false}
                    >
                      submit
                    </Button>
                  </Box>
                </form>
              </Modal> */}
            </Box>
            <Typography
              onClick={downloadChat}
              sx={Styles.AIText}
              onMouseEnter={handleMouseEnterDownload}
              onMouseLeave={handleMouseLeaveDownload}
            >
              Download
              <DownloadIcon
                sx={{
                  ml: 0.5,
                  // width: "12px",
                  // height: "auto",
                  color: isHoveredDownload ? "primary.main" : "#000",
                  fontSize: "14px",
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              />
            </Typography>
          </Box>
          <div onClick={toggleMenu}>
            <Typography sx={Styles.AIText}>
              {isMenuOpen ? "Close " : "Open pdfs "}
            </Typography>
          </div>
        </Box>
        <nav style={navStyle}>
          <ul>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                // width: '35%',
                mx: "auto",
                gap: "40px",
                flexDirection: "column",
                maxHeight: "40vh",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Box
                sx={{
                  height: "auto",
                  maxHeight: { xl: "200px", lg: "200px", md: "200px" },
                  overflowY: "auto",
                  width: "90%",
                  mt: 0,
                }}
              >
                {newChatList?.data?.data
                  ?.slice()
                  .reverse()
                  ?.map((item, index) => {
                    if (activeBox === null || activeBox === undefined) {
                      if (index === 0) {
                        handleBoxClick(item);
                        // setChatId(item);
                        ChatMessagesRefecth();
                      }
                    }
                    return (
                      <Box
                        key={item._id}
                        onClick={() => {
                          handleBoxClick(item);
                          setChatId(item);
                          clearInputBox();
                          ChatMessagesRefecth();
                          ChatMessagesClick(item?._id);
                          setPageNumber(1);
                          toggleMenu();
                        }}
                        sx={{
                          ...SinglePdfDes,
                          transition:
                            "background 0.3s , color 0.3s , borderLeft 0.3s ease-in-out",
                          "&:hover": {
                            color: "#000",
                            background: "#fff",
                            borderLeft: "1px solid",
                            borderLeftColor: "primary.main",
                            opacity: 1,
                          },
                          opacity: expanded === index ? 1 : 0.8,
                          backgroundColor:
                            id === item._id ? "#fff" : "transperent",
                          borderLeft:
                            id === item._id ? "1px solid" : "transperent",
                          // borderLeftColor: 'primary.main',
                          borderLeftColor: "1px solid",
                          borderImageSource:
                            " linear-gradient(90deg, rgba(115,211,242,1) 0%, rgba(186,145,237,1) 34%, rgba(255,96,192,1) 66%, rgba(255,140,95,1) 100%)",
                          borderImageSlice: 1,
                          // background:
                          //   'linear-gradient(90deg, rgba(115, 211, 242, 1) 0%, rgba(186, 145, 237, 1) 33%, rgba(255, 96, 192, 1) 66%, rgba(255, 140, 95, 1) 100%)',
                        }}
                      >
                        <Box
                          component={"img"}
                          src={chaticon}
                          alt=""
                          sx={{
                            height: "20px",
                            maxWidth: "20px",
                          }}
                        />
                        <Typography
                          sx={{
                            ...Styles.que,
                            textOverflow: "ellipsis",
                            textAlign: "left",
                          }}
                        >
                          {item?.name}
                        </Typography>
                        <Box
                          onClick={() => handleDeleteItem(item?._id)}
                          component={"img"}
                          src={
                            isTrashIconHovered === item._id ? trashTheme : trash
                          }
                          onMouseEnter={() => handleTrashIconHover(item?._id)}
                          onMouseLeave={handleMouseLeaveHoevr}
                          sx={{
                            height: "20px",
                            maxWidth: "20px",
                          }}
                        />
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </ul>
          {[1].map((item, index) => (
            <>
              <div
                key={index}
                style={{
                  width: "75%",
                  height: "40%",
                  margin: "auto",
                  // display: 'flex',
                  // justifyContent: 'space-between',
                  // flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    borderRight: "2px solid rgba(232, 232, 232, 2)",
                    borderLeft: "2px solid rgba(232, 232, 232, 2)",
                    borderTop: "2px solid rgba(232, 232, 232, 2)",
                    borderBottom: "none",
                    p: 0.8,
                    borderRadius:
                      activeIndex === index
                        ? "10px 10px 0px 0px"
                        : "10px 10px 10px 10px",
                    background: "#fff",
                    ml: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={Styles.footersubText}>
                      All Judgements
                    </Typography>
                    <Box
                      className="accordion-title"
                      mt={0.3}
                      onClick={() => onTitleClick(index)}
                    >
                      {index === activeIndex ? (
                        <KeyboardArrowUpIcon
                          sx={{
                            color: "rgba(84, 84, 84, 1)",
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <ExpandMoreIcon
                          sx={{
                            color: "rgba(84, 84, 84, 1)",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box
                  className="accordion-content"
                  style={{
                    display: index === activeIndex ? "block" : "none",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      borderRight: "2px solid rgba(232, 232, 232, 2)",
                      borderLeft: "2px solid rgba(232, 232, 232, 2)",
                      borderBottom: "2px solid rgba(232, 232, 232, 2)",
                      borderTop: "none",
                      p: 1,
                      borderRadius: "0px 0px 10px 10px",
                      background: "#fff",
                      ml: 1,
                    }}
                  >
                    <Box
                      sx={{
                        borderTop: "1.5px solid rgba(232, 232, 232, 2)",
                        width: "100%",
                        mx: "auto",
                        mt: -1,
                      }}
                    />
                    {[1, 2, 3].map((item, index) => (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          mt: 0.5,
                        }}
                      >
                        <Box
                          component={"img"}
                          src={pageicon}
                          alt="img"
                          sx={{ height: "auto", width: "auto" }}
                        />
                        <Box>
                          <Typography sx={Styles.tableBodySubText}>
                            Supreme Court
                          </Typography>
                          <Typography sx={Styles.subheadingtabletext}>
                            11 June
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
                {/* <Box
                  sx={{
                    ...Styles.LogOut,
                    borderRadius: '10px',
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => {
                    cookie.remove('Bearer');
                    window.location.href = '/';
                  }}
                >
                  <Box>Log Out</Box>
                  <Box
                    component={'img'}
                    src={isHovered ? logoutlite : logout}
                    alt="img"
                    sx={{
                      height: 'auto',
                      width: {
                        xl: '20px',
                        lg: '20px',
                        md: '20px',
                        sm: '15px',
                        xs: '15px',
                      },
                    }}
                  />
                </Box> */}
              </div>
            </>
          ))}
        </nav>
        <Paper
          elevation={2}
          sx={{
            boxShadow: "0px 10px 20px 0px rgba(147, 147, 147, 0.25)",
            borderRadius: "18px",
            mt: 0,
            width: "95%",
            mx: "auto",
          }}
        >
          <Box sx={{ ...Styles.MessageViewStyle }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: "20px",
                py: "0px",
                borderBottom: "2px solid",
                borderBottomColor: "primary.main",
                width: "80%",
                mx: "auto",
                p: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    maxWidth: { md: "300px", sm: "200px", xs: "200px" },
                    overflowX: "auto",
                  }}
                >
                  {batchList?.data?.data?.map((item, index) => {
                    const isSelected = selectedBatch === index;
                    if (!item.questions || item.questions.length === 0) {
                      return null;
                    }
                    return (
                      <Box
                        key={index}
                        sx={{
                          background: "rgba(247, 249, 251, 1)",
                          height: "auto",
                          width: "fit-content",
                          p: 0.8,
                          borderRadius: "5px",
                          gap: "8px",
                          display: "flex",
                          cursor: "pointer",
                          alignItems: "center",
                          maxWidth: "100%",
                        }}
                        onClick={() => handleSelectBatch(index)}
                      >
                        <Box
                          component={"img"}
                          src={isSelected ? batchImg : batchGreyImg}
                          sx={{ width: "auto", height: "10px", flexShrink: 0 }}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Lato",
                            fontSize: "8px",
                            fontWeight: 600,
                            textAlign: "left",
                            width: "auto",
                            color: isSelected
                              ? "rgba(96, 91, 255, 1)"
                              : "rgba(133, 139, 160, 1)",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                {/* <Box onClick={scrollForward} sx={{ cursor: 'pointer' }}>
                  <ChevronRightIcon
                    sx={{
                      '&:hover': { color: 'primary.main' },
                    }}
                  />
                </Box> */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "45%",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <>
                    <Ring size={30} lineWeight={12} speed={2} color="#605BFF" />
                  </>
                ) : (
                  <>
                    {/* <Box
                      component={'img'}
                      src={isShareIconHovered ? share : shareInactive}
                      alt="img"
                      sx={{ ...Styles.IconStyle, width: "16px" }}
                      // onClick={handleShareClick}
                      onClick={() => navigate(`/viewsharechat/${id}`)}
                      onMouseEnter={handleShareIconHover}
                      onMouseLeave={handleMouseLeaveHoevr}
                    /> */}
                  </>
                )}
              </Box>
            </Box>
            <Box ref={messageBoxRef} sx={mainChatBox}>
              {ChatMessages?.data?.data?.map((item, index) => {
                // let pageNumber;
                let message = "";
                // const match = item?.message?.match(/Page Number: (\d+)/);

                // if (match && match[1]) {
                //   // Extracted page number
                //   pageNumber = parseInt(match[1], 10);

                //   // Extract the message part
                //   const messageMatch = item?.message?.match(/Output: Data(.+)/);
                //   message =
                //     messageMatch && messageMatch[1] ? messageMatch[1] : '';
                // } else {
                message = item?.message;
                // }
                const isLastMessage =
                  index === ChatMessages?.data?.data?.length - 1;
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems:
                        item.role === "user" ? "flex-end" : "flex-start",
                      mb: "20px",
                      mt: "20px",
                      width: "90%",
                      mx: "auto",
                    }}
                  >
                    <div
                      style={{
                        textAlign: item.role === "user" ? "right" : "left",
                        marginTop: "25px",
                        position: "relative",
                      }}
                    >
                      {item.role === "user" ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            // width: '100%',
                            right: "0px",
                            float: "right",
                            marginTop: "-20px",
                            // marginLeft: '40px',
                            ml: "auto",
                            // mr: 5,
                          }}
                        >
                          <Typography sx={Styles.timeStaps}>
                            {new Date(item.createdAt).getHours()}:{" "}
                            {new Date(item.createdAt).getMinutes()}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            right: "0px",
                            marginTop: "-20px",
                            // marginLeft: '40px',
                            // ml: 4,
                            mr: 0,
                          }}
                        >
                          <Typography sx={Styles.timeStaps}>
                            {new Date(item.createdAt).getHours()}:{" "}
                            {new Date(item.createdAt).getMinutes()}
                          </Typography>
                        </Box>
                      )}
                      <Box
                        sx={{
                          ...Styles.messagesText,
                          marginBottom: "5px",
                          maxWidth: "70%",
                          fontSize: "11px",
                          backgroundColor:
                            item.role === "user" ? "secondary.main" : "#CEE8EE",
                          color: item.role === "user" ? "#fff" : "#000",
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Box>
                            {/* <Markdown>{message}</Markdown> */}
                            {isLastMessage &&
                            animateLastMessage &&
                            lastMessageId === item._id &&
                            item.role !== "user" ? (
                              <AnimatedText
                                text={message}
                                speed={50}
                                lineByLine={false}
                                setLastMessageId={setLastMessageId}
                              />
                            ) : (
                              <Markdown>{message}</Markdown>
                            )}
                            {/* <Tooltip title="copy" placement="bottom" arrow>
                              <Box>
                                {item.role !== 'user' && (
                                  <Box
                                    onClick={() =>
                                      copyToClipboard(item.message, item._id)
                                    }
                                    sx={{
                                      minWidth: 'auto',
                                      padding: '2px',
                                      width: '10px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <ContentCopyIcon
                                      sx={{
                                        fontSize: '16px',
                                        color:
                                          copiedMessageId === item._id
                                            ? 'primary.main'
                                            : 'inherit',
                                      }}
                                    />
                                  </Box>
                                )}
                              </Box>
                            </Tooltip> */}
                            {item.role !== "user" &&
                            item.fileData &&
                            item.fileData.length > 0 ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "25px",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "95%",
                                  mx: "auto",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "10px",
                                    width: "100%",
                                    maxWidth: "300px",
                                    overflowX: "auto",
                                  }}
                                >
                                  {/* sx={Styles.sourceLink} */}
                                  {/* comment out for now */}
                                  {item.fileData
                                    .slice() // Create a shallow copy to avoid mutating the original array
                                    .sort((a, b) => {
                                      if (!isSorted) return 0; // If sorting is disabled, keep original order

                                      const yearA = extractYear(
                                        typeof a === "string"
                                          ? a
                                          : a?.metadata?.fileName?.format
                                      );
                                      const yearB = extractYear(
                                        typeof b === "string"
                                          ? b
                                          : b?.metadata?.fileName?.format
                                      );

                                      return yearB - yearA; // Descending order
                                    })
                                    .map((url, index) => {
                                      // Determine the base file name or fallback
                                      const baseFileName =
                                        url?.metadata?.fileName?.format ??
                                        `Source Link ${index + 1}`;

                                      // Check if the base file name already exists in the map
                                      if (fileNameOccurrences[baseFileName]) {
                                        // Increment the count and append {n} to the file name
                                        fileNameOccurrences[baseFileName]++;
                                      } else {
                                        // Initialize the count for a new file name
                                        fileNameOccurrences[baseFileName] = 1;
                                      }

                                      // Generate the unique file name with {n} if necessary
                                      const uniqueFileName =
                                        fileNameOccurrences[baseFileName] > 1
                                          ? `${baseFileName} (${
                                              fileNameOccurrences[
                                                baseFileName
                                              ] - 1
                                            })`
                                          : baseFileName;

                                      return typeof url === "string" ? (
                                        <Box
                                          key={index}
                                          sx={{
                                            ...Styles.sourceLink,
                                            flexWrap: "wrap",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "2px", // Space between link and icons
                                          }}
                                        >
                                          <Box
                                            onClick={() => openSourceLink(url)}
                                          >
                                            {uniqueFileName}
                                          </Box>
                                        </Box>
                                      ) : (
                                        <>
                                          <Tooltip
                                            title={url?.pageContent}
                                            key={index}
                                            placement="right-end"
                                            componentsProps={{
                                              tooltip: {
                                                sx: {
                                                  maxHeight: "200px",
                                                  backgroundColor: "#FFF",
                                                  color: "#000",
                                                  fontSize: "14px",
                                                  overflowY: "auto",
                                                  padding: "8px",
                                                  borderRadius: "4px",
                                                  boxShadow:
                                                    "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                                },
                                              },
                                              popper: {
                                                sx: {
                                                  maxWidth: "400px", // Set the maximum width of the tooltip if needed
                                                },
                                              },
                                            }}
                                          >
                                            <Box
                                              key={index}
                                              sx={{
                                                ...Styles.sourceLink,
                                                display: "flex",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                gap: "8px", // Space between link and icons
                                              }}
                                              onClick={() => {
                                                openSourceLink(
                                                  url?.metadata?.source ??
                                                    url?.metadata?.fileName
                                                      ?.fileUrl,
                                                  url?.metadata?.pageNumber ??
                                                    url?.metadata?.page - 1
                                                );
                                              }}
                                            >
                                              <span
                                                style={{
                                                  borderBottom:
                                                    "1px solid blue",
                                                }}
                                              >
                                                {uniqueFileName}
                                              </span>
                                            </Box>
                                          </Tooltip>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              gap: "0px",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <IconButton
                                              aria-label="Bookmark"
                                              onClick={(e) => {
                                                handleBookmark(
                                                  url?.metadata?.fileName?._id,
                                                  url?.metadata?.fileName
                                                    ?.fileName,
                                                  url?.metadata?.fileName
                                                    ?.fileUrl,
                                                  e
                                                );
                                              }}
                                              size="small"
                                              sx={{
                                                p: 0,
                                              }}
                                            >
                                              <BookmarkIcon
                                                fontSize="small"
                                                sx={{
                                                  color: bookmarkedItems[
                                                    url?.metadata?.fileName?._id
                                                  ]
                                                    ? "red"
                                                    : "inherit",
                                                  width: "15px",
                                                }}
                                              />
                                            </IconButton>
                                            <IconButton
                                              aria-label="Email"
                                              onClick={(e) => {
                                                handleOpenMail();
                                                e.stopPropagation();
                                              }}
                                              size="small"
                                              sx={{
                                                p: 0,
                                              }}
                                            >
                                              <EmailIcon
                                                fontSize="small"
                                                sx={{ width: "15px" }}
                                                onClick={() => {
                                                  setPdfUrl(
                                                    url?.metadata?.fileName
                                                      ?.fileUrl
                                                  );
                                                }}
                                              />
                                            </IconButton>
                                          </Box>
                                        </>
                                      );
                                    })}
                                  <Modal
                                    open={ChatModal}
                                    onClose={() => {
                                      handleChatModalopen();
                                    }}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    sx={{ zIndex: 9999 }}
                                    BackdropProps={{
                                      style: {
                                        backgroundColor: "transparent", // Makes the backdrop transparent
                                      },
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        ...modalStyle,
                                        height: "500px",
                                        width: "85%",
                                      }}
                                    >
                                      <CloseIcon
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleCloseSingle();
                                        }}
                                        sx={{
                                          cursor: "pointer",
                                        }}
                                      />

                                      {!isPdfVisible ? (
                                        <Box
                                          sx={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          Click on a source link to preview the
                                          PDF
                                        </Box>
                                      ) : (
                                        showPdf && (
                                          <Viewer
                                            fileUrl={showPdf}
                                            initialPage={pageNumber}
                                            plugins={[
                                              defaultLayoutPluginInstance,
                                              getFilePluginInstance,
                                            ]}
                                          />
                                        )
                                      )}
                                    </Box>
                                  </Modal>
                                  <Modal
                                    open={openMail}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                  >
                                    <Box sx={style}>
                                      <IconButton
                                        aria-label="close"
                                        onClick={handleCloseMail}
                                        sx={{
                                          position: "absolute",
                                          right: 8,
                                          top: 0,
                                          color: "#555",
                                        }}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                      <TextField
                                        id="message"
                                        name="message"
                                        multiline
                                        rows={3}
                                        sx={textfeilddesign}
                                        value={email}
                                        onChange={(e) =>
                                          setEmail(e.target.value)
                                        } // Ensure state updates properly
                                        required
                                        placeholder="Enter Email to send pdf"
                                      />
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          mt: 2,
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          sx={{ mr: 2 }}
                                          onClick={() => {
                                            if (MailLoder) {
                                              return;
                                            } else {
                                              sendPdfMail();
                                            }
                                          }}
                                        >
                                          {MailLoder ? (
                                            <ClipLoader
                                              color="white"
                                              size="20"
                                            />
                                          ) : (
                                            "Send"
                                          )}
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Modal>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <Tooltip
                                    title="sequence links order"
                                    placement="bottom"
                                    arrow
                                  >
                                    <ImportExportIcon
                                      onClick={() => {
                                        toggleSorting();
                                      }}
                                      sx={{
                                        cursor: "pointer",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </Tooltip>
                                  <Tooltip
                                    title="copy"
                                    placement="bottom"
                                    arrow
                                  >
                                    <Box>
                                      {item.role !== "user" && (
                                        <Box
                                          onClick={() =>
                                            copyToClipboard(
                                              item.message,
                                              item._id
                                            )
                                          }
                                          sx={{
                                            minWidth: "auto",
                                            padding: "2px",
                                            width: "10px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <ContentCopyIcon
                                            sx={{
                                              fontSize: "16px",
                                              color:
                                                copiedMessageId === item._id
                                                  ? "primary.main"
                                                  : "inherit",
                                            }}
                                          />
                                        </Box>
                                      )}
                                    </Box>
                                  </Tooltip>
                                </Box>
                              </Box>
                            ) : null}
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </Box>
                );
              })}
              {chatMessageLoading ? (
                <Box sx={{ ml: 4, mb: 4 }}>
                  <DotPulse
                    size={10}
                    lineWeight={2}
                    speed={2}
                    color="rgba(9, 26, 50, 1)"
                  />
                </Box>
              ) : null}
            </Box>
            <Box sx={Styles.scrollTagBox}>
              {followUpMessages?.data?.questions?.map((item, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      ...Styles.tagText,
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(237, 237, 255, 1)",
                      },
                    }}
                    onClick={() => handleTagClick(item)}
                  >
                    + &nbsp; &nbsp; {item}
                  </Box>
                );
              })}
            </Box>
            {newChatList?.data?.data?.length > 0 ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "93%",
                    mx: "auto",
                    gap: "10px",
                    background: "transparent",
                    mb: 2,
                    mt: 0.5,
                  }}
                >
                  <Box
                    onClick={handleSpeechRecognition}
                    sx={{
                      ...Styles.micStyle,
                      transform: isListening ? "scale(1.3)" : "scale(1)",
                      borderRadius: isListening ? "50px" : "10px",
                      boxShadow: isListening
                        ? "0px 0px 0px 2.5px rgba(104, 133, 140, 1)"
                        : "none",
                    }}
                    // onMouseOver={(e) => {
                    //   e.currentTarget.style.transform = 'scale(1.3)';
                    // }}
                    // onMouseOut={(e) => {
                    //   e.currentTarget.style.transform = 'scale(1.2)';
                    // }}
                  >
                    <MicIcon
                      sx={{
                        color: "white",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      ...Styles.messageInputStyle,
                      "&:hover": {
                        border: "2px solid",
                        borderColor:
                          // !isInputFocused || chatMessageLoading
                          //   ? '#E0E0E0'
                          //   : 'primary.main',
                          isInputFocused && chatMessageLoading
                            ? "primary.main"
                            : "#091A32",
                      },
                      opacity: chatMessageLoading ? 0.5 : 1,
                      borderColor:
                        !isInputFocused || chatMessageLoading
                          ? "primary.main"
                          : "#091A32",
                    }}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Input
                        value={newMessage}
                        name="Ask any Question"
                        onChange={(e) => {
                          // if (Number(routeId) === 1 || isCommentList) {
                          //   toast.info('please add one comment first');
                          // } else {
                          //   Typing_Changes(e);
                          // }
                          if (
                            // Number(routeId) === 0 ||
                            // isCommentList ||
                            e.target.value &&
                            e.target.value.length > 1000
                          ) {
                            if (
                              e.target.value &&
                              e.target.value.length > 1000
                            ) {
                              toast.info(
                                "Please limit your input to 1000 characters."
                              );
                            } else {
                              toast.info("Please add one comment first.");
                            }
                          } else {
                            Typing_Changes(e);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.target.value === "" || !e.target.value) return;
                          if (e.key === "Enter") {
                            send_Massages();
                          }
                        }}
                        fullWidth
                        placeholder="Ask any Question"
                        sx={{ fontSize: "16px", ml: 0.8 }}
                        disableUnderline
                        // disabled={
                        //   Number(routeId) === 1 ? true : false || isCommentList
                        // }
                        disabled={chatMessageLoading}
                      />
                    </Box>

                    <button
                      disabled={chatMessageLoading || newMessage === ""}
                      style={{
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={sendicon}
                        alt=""
                        onClick={() => {
                          send_Massages();
                        }}
                        // style={Styles.SendIcon}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.1)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                        }}
                        // sx={{ height: "auto", width: "45px" }}
                      />
                    </button>
                  </Box>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 2,
                }}
              >
                <Box
                  sx={{
                    ...Styles.newChat,
                    width: "auto",
                    height: "auto",
                    p: 0.5,
                  }}
                  onClick={() => newChatNameSubmit()}
                >
                  <Box>+</Box>
                  Please create first a chat
                </Box>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </Paper>
  );
};

export default MessageBox;

const mainChatBox = {
  height: "100%",
  minHeight: { xl: "61vh", lg: "61vh", md: "61vh", sm: "61vh", xs: "61vh" },
  maxHeight: "61vh",
  overflowY: "auto",
  overflowX: "hidden",
  margin: "0px",
  marginTop: { xl: "20px", lg: "5px", md: "0px" },
  backgroundImage: `url(${BGImage})`,
  backgroundSize: "cover",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: 400, lg: 400, md: 400, sm: 300, xs: 300 },
  bgcolor: "background.paper",
  background: "linear-gradient(135deg, #ece9e6 0%, #ffffff 100%)",
  borderRadius: "12px",
  boxShadow: 24,
  p: { xl: 3, lg: 3, md: 3, sm: 1.5, xs: 1.5 },
  outline: "none", // Remove the default border
};

const SinglePdfDes = {
  display: "flex",
  justifyContent: "space-between",
  width: "80%",
  mx: "auto",
  mt: 1,
  borderRadius: "0px 10px 10px 0px",
  p: 1.5,
  cursor: "pointer",
};

const signInBtn = {
  borderRadius: "10px",
  backgroundColor: "primary.main",
  color: "#FFF",
  fontFamily: "Lato",
  fontSize: { xl: "1rem", lg: "1rem", md: "1rem", sm: "0.6rem", xs: "0.6rem" },
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textTransform: "none",
  p: { xl: 2, lg: 2, md: 2, sm: 1.5, xs: 1.5 },
  mt: 4,
  width: "40%",
  mx: "auto",
  textAlign: "center",
  display: "flex",
  justifcontent: "center",
  "&:hover": {
    backgroundColor: "primary.main",
  },
};
const modalStyle = {
  position: "absolute",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #19A8FC",
  borderColor: "primary.main",
  boxShadow: "0px 10px 20px 0px rgba(147, 147, 147, 0.25)",
  p: 2,
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "50%",
  width: "60%",
};

const modalStylePdf = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  // boxShadow: 10,
  pt: 2,
  px: 4,
  pb: 3,
};

const textfeilddesign = {
  mt: 2,
  width: "100%",
  "& .MuiInput-underline:before": {
    borderBottomColor: "secondary.main", // Normal state
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottomColor: "primary.main", // Hover state
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "secondary.main", // Focused state
  },
};
