import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import { School, Work } from '@mui/icons-material';

// Styled Components
const ToggleContainer = styled(Box)({
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  maxWidth: '600px', // Keep original desktop width
  margin: '0 auto',
  '@media (max-width: 600px)': {
    maxWidth: '280px', // Adjust for small screens
  },
});

const ToggleButtonGroup = styled(Box)({
  position: 'relative',
  backgroundColor: '#f5f5f5',
  borderRadius: '12px',
  padding: '4px',
  display: 'flex',
  gap: '4px',
  width: '320px', // Original width
  height: '40px', // Maintain height
  '@media (max-width: 600px)': {
    width: '220px', // Reduce width for mobile
    height: '40px', // Smaller height for mobile
  },
});

const Slider = styled(Box)(({ active }) => ({
  position: 'absolute',
  width: 'calc(50% - 4px)',
  height: 'calc(100% - 8px)',
  background: 'white',
  borderRadius: '8px',
  transition: 'transform 0.4s ease-in-out',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  transform:
    active === '/signup' ? 'translateX(0)' : 'translateX(calc(100% + 4px))',
  '@media (max-width: 600px)': {
    height: 'calc(100% - 6px)', // Slightly smaller slider on mobile
  },
}));

const ToggleButton = styled('button')(({ active }) => ({
  position: 'relative',
  padding: '12px 24px',
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  fontFamily: 'inherit',
  fontSize: '1rem',
  fontWeight: 500,
  color: active ? '#1976d2' : '#666',
  transition: 'color 0.3s ease',
  zIndex: 1,
  width: '50%', // Equal button width
  '@media (max-width: 600px)': {
    padding: '8px 12px', // Reduce padding
    fontSize: '0.85rem', // Make text smaller
  },
}));

const Toggle = () => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <ToggleContainer>
      <ToggleButtonGroup>
        <Slider active={pathname} />
        <ToggleButton
          active={pathname === '/signup'}
          onClick={() => handleNavigation('/signup')}
        >
          <Work fontSize="small" />
          <span>Professional</span>
        </ToggleButton>
        <ToggleButton
          active={pathname === '/signupstudent'}
          onClick={() => handleNavigation('/signupstudent')}
        >
          <School fontSize="small" />
          <span>Student</span>
        </ToggleButton>
      </ToggleButtonGroup>
    </ToggleContainer>
  );
};

export default Toggle;
