import axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { GetTokenFunc } from '../utils/GetToken';

// import { GetTokenFunc } from '../../utils/GetToken';
export const useGooleLoginAuth = () => {
  return useMutation((credentials) => {
    return axios.post('api/v1/user/google-register', {
      credential: credentials,
    });
  });
  //   return { data, isLoading, error, mutate };
};

export const useSignUp = () => {
  return useMutation(async (data) => {
    return await axios.post('api/v1/user/register', data);
    // Assuming your API returns some data
  });
};
export const useSignUpForStudent = () => {
  return useMutation(async (data) => {
    return await axios.post('api/v1/user/student_register', data);
    // Assuming your API returns some data
  });
};

export const useSignIn = () => {
  return useMutation(async (data) => {
    return await axios.post('api/v1/user/login', data);
    // Assuming your API returns some data
  });
};

export const useForgotPassword = () => {
  return useMutation(async (data) => {
    return await axios.post('api/v1/user/forgot_password', data);
    // Assuming your API returns some data
  });
};
export const useUpdateMode = () => {
  return useMutation(async (data) => {
    return await axios.put('api/v1/user/update-prompt-type', data, {
      headers: {
        Authorization: `Bearer ${GetTokenFunc()}`,
      },
    });
    // Assuming your API returns some data
  });
};
