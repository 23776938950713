import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { GetTokenFunc } from '../../utils/GetToken';

export const useGetUsersSinglePdfs = () => {
  return [];
  useQuery({
    queryKey: ['user-pdf', 'user-pdfs-single'],
    queryFn: async () => {
      return await axios.get('api/v1/chat-session?userFiles=false', {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

export const useGetUsersPdfs = () => {
  return [];
  useQuery({
    queryKey: ['user-pdf', 'user-pdfs-multiple'],
    queryFn: async () => {
      return await axios.get('api/v1/file/get-chats?singleFile=false', {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};
// api/v1/file/get-chats?singleFile=false
export const useDeleteChat = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id }) => {
      return await axios.delete('api/v1/chat-session/' + id, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user-pdf'] });
    },
  });
};
export const useDeleteAllChat = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({}) => {
      return await axios.delete('api/v1/chat-session/all', {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user-pdf'] });
    },
  });
};
export const useDeleteChatPdf = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id }) => {
      return await axios.delete('api/v1/chat/' + id, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user-pdf-chat'] });
    },
  });
};

export const useCreateFolderName = () => {
  return useMutation({
    mutationKey: ['create-folder'],
    mutationFn: async (data) => {
      return await axios.post('api/v1/file/create_folder', data, {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};

export const useGetFolder = () => {
  return useQuery({
    queryKey: ['folder-list'],
    queryFn: async () => {
      return await axios.get('api/v1/file/get_folder', {
        headers: {
          Authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
    },
  });
};
