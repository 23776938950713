import { Box, Paper, Typography } from '@mui/material';
import axios from 'axios';
import cookie from 'js-cookie';
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import 'react-toastify/dist/ReactToastify.css';
import { GetTokenFunc } from '../../utils/GetToken';
import Header from '../HeaderUi/Header';
import Typewriter from 'typewriter-effect/dist/core';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Ring, Waveform } from '@uiball/loaders';
import * as Styles from '../../Common/Styles.js';
import OpinionComponent from './OpinionComponent.jsx';
import BlogsComponent from './BlogsComponents.jsx';
import Articles from './Articles.jsx';
import Footer from './Footer.jsx';
import courtLadies from '../../Assets/mainPage/courtLadies.svg';
import paramainlogo from '../../Assets/mainPage/paramainlogo.svg';
import uprightarr from '../../Assets/mainPage/uprightarr.svg';
import { motion } from 'framer-motion';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';
import News from './News.jsx';
import WorkPlace from './WorkPlace.jsx';
const NewHome = () => {
  const navigate = useNavigate();
  const typewriterRef = useRef(null);
  useEffect(() => {
    const typewriter = new Typewriter(typewriterRef.current, {
      loop: true,
    });

    typewriter
      .pauseFor(1000)
      .typeString('Para has partnered with Institute of Law, Nirma University')
      .pauseFor(1000)
      .deleteAll()
      .typeString('Our strategic partnership with Lex Chambers')
      .pauseFor(1000)
      .deleteAll()
      .typeString('Explore ParaSearch – our engine for case law research')
      .pauseFor(1000)
      .deleteAll()
      .start();
  }, []);
  return (
    <div style={{ overflow: 'hidden' }}>
      <Header />
      <Paper elevation={0} sx={mainHomePaper}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box sx={Styles.TopBlognavigateText}>
            <Typography
              ref={typewriterRef}
              sx={{
                fontFamily: 'inter',
                fontSize: {
                  xl: '0.8rem',
                  lg: '0.8rem',
                  md: '0.8rem',
                  sm: '0.6rem',
                  xs: '0.6rem',
                },
                fontWeight: 400,
                color: 'rgba(21, 21, 21, 1)',
              }}
            >
              Lindahl invests in AI – rolling out Leya firm-wide
            </Typography>
            <Box
              onClick={() => {
                navigate('/login');
              }}
              component={'img'}
              src={uprightarr}
              alt=""
              sx={{ height: 'auto', width: '10px' }}
            />
          </Box>
          <Fade top duration={1000}>
            <Box component={'span'} sx={paraText}>
              PARA
            </Box>
          </Fade>
          <Zoom duration={1000}>
            <Typography sx={subText}>
              Your AI Paralegal - for Indian case law research, document
              analysis and drafting
            </Typography>
          </Zoom>
          <Box
            sx={{
              position: 'relative',
              mt: { xl: 0, lg: 0, md: 0, sm: 5, xs: 5 },
            }}
          >
            <Box sx={{ position: 'absolute', bottom: 12, left: 80, zIndex: 1 }}>
              <motion.div
                animate={{ rotate: 360 }}
                transition={{
                  duration: 5,
                  ease: 'linear',
                  repeat: Infinity,
                }}
              >
                <Box
                  component={'img'}
                  src={paramainlogo}
                  sx={{
                    height: 'auto',
                    maxWidth: {
                      xl: '245px',
                      lg: '245px',
                      md: '245px',
                      sm: '180px',
                      xs: '115px',
                    },
                  }}
                />
              </motion.div>
            </Box>
            <Box
              component={'img'}
              src={courtLadies}
              sx={{
                height: 'auto',
                maxWidth: {
                  xl: '800px',
                  lg: '800px',
                  md: '800px',
                  sm: '600px',
                  xs: '400px',
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              mt: { xl: 0, lg: 0, md: 0, sm: 5, xs: 5 },
            }}
          >
            <Box
              component={'a'}
              href="https://www.instagram.com/paraprobot.ai?igsh=eWtvb3gxdWRicXhu"
              target="_blank"
              sx={{ textDecoration: 'none' }}
            >
              <InstagramIcon
                style={{
                  height: '20px',
                  cursor: 'pointer',
                  color: '#000',
                }}
              />
            </Box>
            <Box
              component={'a'}
              href="https://www.linkedin.com/company/para-ai-paralegal/"
              target="_blank"
              sx={{ textDecoration: 'none' }}
            >
              <LinkedInIcon
                sx={{
                  height: '20px',
                  cursor: 'pointer',
                  color: '#000',
                }}
              />
            </Box>
            <Box
              component={'a'}
              href="https://api.whatsapp.com/send?phone=918780961035"
              target="_blank"
              sx={{ textDecoration: 'none' }}
            >
              <WhatsAppIcon
                sx={{
                  height: '20px',
                  cursor: 'pointer',
                  color: '#000',
                }}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
      <WorkPlace />
      {/* <News /> */}
      {/* <Articles /> */}
      <OpinionComponent />
      {/* <BlogsComponent /> */}
      <Footer />
    </div>
  );
};

export default NewHome;
const MainTextStyle = {
  fontFamily: 'Poppins',
  fontSize: {
    xl: '5rem',
    lg: '5rem',
    md: '4rem',
    sm: '4rem',
    xs: '2.5rem',
  },
  fontStyle: 'normal',
  lineHeight: 1.1,
};
const askText = {
  ...MainTextStyle,
  color: '#030229',
  textAlign: 'center',
  fontWeight: 100,
};
const antText = {
  ...MainTextStyle,
  color: '#081930',
  fontWeight: 900,
  fontFamily: 'Manrope',
  textTransform: 'capitalize',
};
const paraText = {
  fontFamily: 'Inter',
  fontSize: { xl: '5rem', lg: '4rem', md: '4rem', sm: '4rem', xs: '2.5rem' },
  fontWeight: 500,
  textTransform: 'capitalize',
  color: 'rgba(21, 21, 21, 1)',
};
const LawText = {
  ...MainTextStyle,
  background:
    'linear-gradient(123deg, #4BF7F3 10.18%, #AD9DF0 20.81%, #FF51E0 82.15%, #FF9943 91.35%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 900,
};
const subText = {
  color: '#6D6D6D',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontWeight: 350,
  fontSize: {
    xl: '1rem',
    lg: '0.8rem',
    md: '0.8rem',
    sm: '0.8rem',
    xs: '0.5rem',
  },
  width: { xl: '80%', lg: '80%', md: '80%', sm: '80%', xs: '80%' },
  mx: 'auto',
  mt: 1,
};
const MulishFontTextStyle = {
  fontFamily: 'poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  color: '#676767',
  textAlign: 'center',
  lineHeight: '12px',
};
const uploadText = {
  color: 'rgba(0, 0, 0, 0.88)',
  fontFamily: 'Poppins',
  fontSize: { xl: '12px', lg: '10px', md: '8px', sm: '6px', xs: '6px' },
  fontStyle: 'normal',
  fontWeight: 500,
  textAlign: 'center',
};
const fileUploadBox = {
  background: 'red',
  // border: "2px solid #009FFC",
  border: '1px dashed #D9D9D9',
  '&:focus': {
    outline: 'none',
  },
  '&:focus-within': {
    border: '2px dashed ##009FFC',
  },
  bgcolor: 'background.paper',
  // boxShadow: 5,
  borderRadius: {
    xl: '20px',
    lg: '20px',
    md: '16px',
    sm: '10px',
    xs: '10px',
  },
  gap: 0.5,
  p: { xl: 2, lg: 2, md: 2, sm: 1, xs: 1 },
  width: {
    xl: '1000px',
    lg: '1000px',
    md: '700px',
    sm: '400px',
    xs: '220px',
  },
  height: {
    xl: '130px',
    lg: '130px',
    md: '100px',
    sm: '70px',
    xs: '50px',
  },
};
const mainHomePaper = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  minWidth: '100vw',
  flexDirection: 'column',
  // backgroundImage: `url(${HomeBg})`,
  backgroundSize: 'cover',
  objectFit: 'contain',
  position: 'relative',
  zIndex: 0,
};
const bottomText = {
  fontFamily: 'Manrope',
  fontSize: { xl: '28px', lg: '28px', md: '16px', sm: '14px', xs: '12px' },
  fontWeight: 700,
};
const bottomSubText = {
  fontFamily: 'Manrope',
  fontSize: { xl: '11px', lg: '11px', md: '10px', sm: '8px', xs: '6px' },
  fontWeight: 600,
  color: '#000000',
  width: '80%',
};
