import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  Grid,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  Fade,
  Select,
} from '@mui/material';
import ReactGA from 'react-ga';
import sms from '../../Assets/LoginSignup/sms.png';
import lock from '../../Assets/LoginSignup/lock.png';
import googleimg from '../../Assets/LoginSignup/googleimg.png';
import user from '../../Assets/LoginSignup/user.png';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import mainLogo from '../../Assets/Header/mainLogo.png';
import { Link, useNavigate } from 'react-router-dom';
import menuHorz from '../../Assets/Header/menuHorz.png';
import phone from '../../Assets/Header/phone.png';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import pricetag from '../../Assets/Header/pricetag.png';
import setting from '../../Assets/Header/setting.png';
import help from '../../Assets/Header/help.png';
import num from '../../Assets/LoginSignup/num.svg';
import ipimg from '../../Assets/LoginSignup/ipimg.svg';
import gglreg from '../../Assets/LoginSignup/gglreg.svg';
import paralogo from '../../Assets/Header/paralogo.svg';
import cityimg from '../../Assets/LoginSignup/cityimg.svg';
import * as Styles from '../../Common/Styles.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useSignUp,
  useGooleLoginAuth,
  useSignUpForStudent,
} from '../../Hooks/useSignUp';
import cookie from 'js-cookie';
import axios from 'axios';
import Toggle from './Toggle.jsx';
const SignUp = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [profession, setProfession] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [ipadd, setIPadd] = useState([]);
  const open = Boolean(anchorEl);
  const pathName = window.location.pathname;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const {
    mutate: GoogleLoginMutate,
    isLoading: GoogleLoading,
    isError: GoogleError,
  } = useGooleLoginAuth();

  const login = useGoogleLogin({
    onSuccess: (res) => {
      GoogleLoginMutate(res.code, {
        onSuccess: (response) => {
          cookie.set('Bearer', response.data.token);
          navigate('/mainchat/:id');
        },
        onError: (error) => {
          return;
        },
      });
    },
    onError: (error) => console.log('err', error),
    flow: 'auth-code',
  });

  // sign up integration

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(
      z
        .object({
          fullName: z.string().min(3),
          email: z.string().email(),
          password: z.string().min(8),
          city: z.string().min(3),
          mobile: z.string().min(10).max(10),
          // profession: z.string().min(),
          // proof: z.instanceof(FileList).optional(),
          // ipaddress: z.string(),
        })
        .refine(
          (data) => {
            if (data.profession === 'student') {
              return data.email.endsWith('.ac') || data.email.endsWith('.edu');
            }
            return true;
          },
          {
            message: 'Student email must end with .ac or .edu',
            path: ['email'],
          }
        )
    ),
  });

  const {
    register: registerForstudent,
    handleSubmit: handleSubmitForStudent,
    setValue: setValueForStudent,
    watch: watchForStudent,
    formState: { errors: errorsForStudent },
  } = useForm({
    resolver: zodResolver(
      z
        .object({
          fullName: z.string().min(3),
          email: z.string().email(),
          password: z.string().min(8),
          city: z.string().min(3),
          mobile: z.string().min(10).max(10),
          proof: z.instanceof(FileList).optional(),
          ipaddress: z.string(),
        })
        .refine(
          (data) => {
            if (data.ipaddress) {
              return (
                data.email.endsWith('.ac.in') ||
                data.email.endsWith('.edu') ||
                data.email.endsWith('.ac')
              );
            }
            return true;
          },
          {
            message: 'Student email must end with ac,.ac.in or .edu',
            path: ['email'],
          }
        )
    ),
  });

  useEffect(() => {
    if (watchForStudent('proof')) {
      const namesArray = Array.from(watchForStudent('proof')).map(
        (file) => file?.name
      );
      setFileNames(namesArray);
    }
  }, [watchForStudent('proof')]);

  useEffect(() => {
    // Reset ipaddress when profession changes
    if (profession !== 'student') {
      setValue('ipaddress', '');
      setIPadd('');
    }
  }, [profession, setValue]);

  const handleProfessionChange = (e) => {
    const value = e.target.value;
    setProfession(value);
    setValue('profession', value);
  };

  const handleIPaddChange = (e) => {
    const value = e.target.value;
    setIPadd(value);
    setValue('ipaddress', value);
  };

  const { mutate: SignUpMutate, isLoading: SignUpLoading, error } = useSignUp();
  const {
    mutate: StudentSignUpMutate,
    isLoading: StudentSignUpLoading,
    Studenterror,
  } = useSignUpForStudent();
  const SignUpSubmit = handleSubmit((data) => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'SignUp Button',
      value: 1,
    });

    SignUpMutate(data, {
      onSuccess: (response) => {
        if (response.status === 201) {
          cookie.set('Bearer', response.data.token);
          navigate('/mainchat/:id');
        }
      },
      onError: (error) => {
        toast.error('Registration failed. Please try again.', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      },
    });
  });
  const SignUpSubmitForStudent = handleSubmitForStudent((data) => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'SignUp Button',
      value: 1,
    });
    const formData = new FormData();
    for (const key in data) {
      if (key === 'proof') {
        Array.from(data[key]).forEach((file) => {
          formData.append(key, file);
        });
      } else if (key !== 'proof') {
        formData.append(key, data[key]);
      }
    }

    StudentSignUpMutate(formData, {
      onSuccess: (response) => {
        if (response.status === 201) {
          cookie.set('Bearer', response.data.token);
          navigate('/mainchat/:id');
        }
      },
      onError: (error) => {
        toast.error('Registration failed. Please try again.', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      },
    });
  });
  const colleges = [
    'A. P. Trivedi Law College',
    'A.M.P. Law College',
    'A.R. Bhatt College of Law',
    'Anand College of Legal Studies',
    'Ananya Institute of Commerce & Law',
    'Androkha Law College',
    'Apollo Institution of Law',
    'Atmiya University',
    'Auro University',
    'Avirat Law College',
    'B.K. Mercantile Bank Law College',
    'Balaji Law College',
    'Banaskantha Mercantile Co-op Bank Ltd Law College',
    'BHAKTA KAVI NARSINH MEHTA UNIVERSITY',
    'Bharti Chandresh Virvadia Law College',
    'C. J. Patel Vidhiyadham Law College',
    'C. U. Shah College of Law',
    'Centre of Excellence, School of Law',
    'Daulatbhai Trivedi Law College',
    'Devraj Law College',
    'Dinshaw Daboo Law College',
    'Dr. B. R. Ambedkar Law College',
    'G.V.M.College of law',
    'Ganpat University',
    'Gayatri Gurukrupa Law College',
    'Geetanjali Institute of Law',
    'GLS Law College',
    'Godhra Law College',
    'Gokul Law College & Gokul Integrated College',
    'Government Law College',
    'Grace Law College',
    'Gujarat National Law University',
    'Gujarat National Law University, Silvassa',
    'GUJARAT UNIVERSITY',
    'Guru Vandana Law College',
    'H. N. Shukla College',
    'H.L. Patel Arts and Commerce College',
    'Harivandana Law College',
    'Hemchandracharya North Gujarat University',
    'Himmat Nagar Kelwani Mandal Law College, Motipura',
    'I.M. Nanavati Law College',
    'ITM Vocational University',
    'Jetpur Law College',
    'JG Institute of Law',
    'Junagadh Law College',
    'K. A. Pandhi English Medium Law College',
    'Kallol Taluka Kelavani Mandal Sanchalit LL.B College',
    'Karnavati University',
    'KRANTIGURU SHYAMJI KRISHNA VERMA KACHCHH UNIVERSITY',
    'Krishna Law College',
    'Kskverma Kachchh University',
    'L. D. Dhanani Law College',
    'Late Sheth Shri M. B. Upadhyay Law College',
    'Law College Jamkandorna',
    'LJ University, School of Law',
    "M. P. Shah Education Society's Law College",
    'M.S. Bhagat C.S.Sonawala Law College',
    'Maha Mandaleshwar Sri Krishnandji Law College',
    'MAHARAJA KRISHNAKUMARSINHJI BHAVNAGAR UNIVERSITY',
    'Maneklal Nanavati Law College',
    'Marwadi Education Foundation Group Of Institutions',
    'Matushree Shantaben Law College',
    'Mohan Goswami College of Law',
    'Motilal Nehru Law College',
    'MSD Kotak Law College',
    'N.V. Joshi Education Trust Sanchalit Shree Saraswati College of Education',
    'Nalanda Law College',
    'Naran Lala Law College',
    'National Forensic Sciences University',
    'Navgujarat Law College',
    'Navrachana University',
    'Navyug Law College',
    'Nirma University',
    'Parul University',
    'PG Center Sheth M N Law College',
    'R. N. Patel Ipcowala School of Law and Justice',
    'Rai University',
    'Rashtriya Raksha University',
    'S.K.M. Law College',
    'S.V. Patel Law College',
    'Sabarmati University',
    'Samarpan School of Law',
    'Sardar Patel Law College',
    'SARDAR PATEL UNIVERSITY',
    'Sarvajanik Law College',
    'Saurabh Law College',
    'SAURASHTRA UNIVERSITY',
    'Seth M.N. Law College',
    'Seth V.S. Law College',
    'Shankersinh Vaghela Bapu Institute of Law',
    'Sheth Dosabhai Lalchand Law College',
    'Sheth H.J. Law College',
    'Sheth V. S. Law College',
    'Shree K.P.Shah Law College',
    'Shree Maruti Vidhya Mandir College',
    'Shree Sarvoday Education Foundation Trust',
    'Shri Atmiya Law College',
    'Shri Dhanjibhai D. Kotiwala Municipal Law College',
    'Shri Gosar Hansraj Gosrani Commerce & Shri D. D. Nagda BBA College',
    'SHRI GOVIND GURU UNIVERSITY',
    'Shri H. M. Patel Mahila Law College',
    'Shri Indubhai Sheth Law College',
    'Shri Maharhi Dayanand Saraswati LL.B College',
    'Shri N.S. Patel Law College',
    'Shri P.M. Patel College of Law and Human Rights',
    'Shri S.M. Shah Law College',
    'Siddharth Law College',
    'Silver Oak Law College',
    'Sir L.A. Shah Law College',
    'Smt. Prabhaben Patel B.Ed College',
    'Smt. R.D. Gardi College',
    'Smt. S.S. Ajmera Municipal Law College',
    'Smt. V. D. Gardi Law College',
    'SVET Law College (Siddhi Vinayak Edu. Trust)',
    'T.N. Rao College of Law',
    'THE MAHARAJA SAYAJIRAO UNIVERSITY OF BARODA',
    'Tolani Institute of Law',
    'V.T Choksi Sarvajanik Law College',
    "Vadodara Jilla Kelavani Mandal Trust's Self Finance Law College",
    'Veer Narmad South Gujarat University',
    'Vinayaka College of Law & Justice',
    'Vivekanand Law College',
  ];

  useEffect(() => {
    if (watchForStudent('proof')) {
      const namesArray = Array.from(watchForStudent('proof')).map(
        (file) => file?.name
      );
      setFileNames(namesArray);
    }
  }, [watchForStudent('proof')]);

  useEffect(() => {
    // Reset ipaddress when profession changes
    if (profession !== 'student') {
      setValue('ipaddress', '');
      setIPadd('');
    }
  }, [profession, setValue]);

  return (
    <Paper elevation={0} sx={Styles.PaperCommonDesReg}>
      <Box
        component={'img'}
        src={paralogo}
        alt=""
        onClick={() => {
          navigate('/');
        }}
        sx={{
          height: '40px',
          width: '40px',
          cursor: 'pointer',
          m: 1,
          display: {
            xl: 'flex',
            lg: 'flex',
            md: 'flex',
            sm: 'flex',
            xs: 'none',
          },
        }}
      />
      <Box
        sx={{
          display: {
            xl: 'none',
            lg: 'none',
            md: 'none',
            sm: 'none',
            xs: 'flex',
          },
        }}
      >
        <header style={headerStyle}>
          <Box sx={{}}>
            <Box
              onClick={() => {
                navigate('/');
              }}
              component={'img'}
              src={paralogo}
              alt=""
              sx={{
                height: 'auto',
                maxWidth: 'auto',
                cursor: 'pointer',
                m: 1,
                transition: '0.5s',
              }}
            />
          </Box>
        </header>
      </Box>
      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={Styles.PaperGrid}
        >
          <Paper elevation={1} sx={Styles.paperBack}>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/login');
              }}
            >
              <Typography
                sx={{
                  ...Styles.signUp,
                  fontSize: {
                    xl: '14px',
                    lg: '14px',
                    md: '12px',
                    sm: '8px',
                    xs: '8px',
                  },
                  transform: 'rotate(6deg)',
                  textAlign: 'right',
                  width: '85%',
                }}
              >
                Sign In
              </Typography>
            </Box>
            <Paper elevation={2} sx={{ ...Styles.paperDes, p: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  mt: { xl: 1, lg: 1, md: 1, sm: 2, xs: 0 },
                }}
              >
                <Typography sx={Styles.welText}>Sign Up Here!</Typography>{' '}
                <Toggle />
              </Box>
              {pathName === '/signup' ? (
                <form onSubmit={SignUpSubmit}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexDirection: 'column',
                      width: {
                        xl: '80%',
                        lg: '80%',
                        md: '80%',
                        xs: '95%',
                        sm: '95%',
                      },
                      mx: 'auto',
                      gap: {
                        xl: '10px',
                        lg: '10px',
                        md: '10px',
                        sm: '10px',
                        xs: '5px',
                      },
                    }}
                  >
                    <Typography sx={Styles.subText}>Full Name</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        mx: 'auto',
                      }}
                    >
                      <Input
                        id="standard-basic"
                        variant="outlined"
                        disableUnderline
                        placeholder="Rahul Sharma"
                        {...register('fullName')}
                        sx={{
                          ...Styles.InputFieldDes,
                          borderColor: isInputFocused
                            ? 'primary.main'
                            : '#E0E0E0',
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <Box
                              component="img"
                              src={user}
                              sx={Styles.iconStyle}
                            />
                          </InputAdornment>
                        }
                        inputProps={{
                          style: {
                            color: '#797979',
                            fontSize: '14px',
                            padding: '4px',
                            paddingLeft: '10px', // Adjust the left padding to make room for the icon
                          },
                        }}
                      />
                      <Typography sx={Styles.validationText}>
                        {errors.fullName?.message}
                      </Typography>
                    </Box>
                    <Typography sx={Styles.subText}>Email</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        mx: 'auto',
                      }}
                    >
                      <Input
                        id="standard-basic"
                        variant="outlined"
                        disableUnderline
                        {...register('email')}
                        placeholder="Email"
                        sx={Styles.InputFieldDes}
                        startAdornment={
                          <InputAdornment position="start">
                            <Box
                              component="img"
                              src={sms}
                              sx={Styles.iconStyle}
                            />
                          </InputAdornment>
                        }
                        inputProps={{
                          style: {
                            color: '#797979',
                            fontSize: '14px',
                            padding: '4px',
                            paddingLeft: '10px', // Adjust the left padding to make room for the icon
                          },
                        }}
                      />
                      <Typography sx={Styles.validationText}>
                        {errors.email?.message}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        gap: '20px',
                        justifyContent: 'center',
                      }}
                    >
                      <Box>
                        <Typography sx={Styles.subText}>Mobile </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            mx: 'auto',
                            // mt: 0.5,
                          }}
                        >
                          <Input
                            id="standard-basic"
                            variant="outlined"
                            disableUnderline
                            {...register('mobile')}
                            placeholder="8989836453"
                            sx={Styles.InputFieldDes}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box
                                  component="img"
                                  src={num}
                                  sx={{ ...Styles.iconStyle, width: '25px' }}
                                />
                              </InputAdornment>
                            }
                            inputProps={{
                              style: {
                                color: '#797979',
                                fontSize: '14px',
                                padding: '7px',
                                paddingLeft: '10px', // Adjust the left padding to make room for the icon
                              },
                            }}
                          />
                        </Box>
                        <Typography sx={Styles.validationText}>
                          {errors.mobile?.message}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={Styles.subText}>City </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            mx: 'auto',
                            mt: 0.2,
                          }}
                        >
                          <Input
                            id="standard-basic"
                            variant="outlined"
                            disableUnderline
                            {...register('city')}
                            placeholder="City"
                            sx={Styles.InputFieldDes}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box
                                  component="img"
                                  src={cityimg}
                                  sx={Styles.iconStyle}
                                />
                              </InputAdornment>
                            }
                            inputProps={{
                              style: {
                                color: '#797979',
                                fontSize: '14px',
                                padding: '4px',
                                paddingLeft: '10px', // Adjust the left padding to make room for the icon
                              },
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Typography sx={Styles.subText}>Choose Password</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        mx: 'auto',
                      }}
                    >
                      <Input
                        id="standard-basic"
                        variant="outlined"
                        disableUnderline
                        type={showPassword ? 'text' : 'password'}
                        {...register('password')}
                        placeholder="Minimum 8 characters"
                        sx={Styles.InputFieldDes}
                        startAdornment={
                          <InputAdornment position="start">
                            <Box
                              component="img"
                              src={lock}
                              sx={Styles.iconStyle}
                            />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPasswordClick}>
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{
                          style: {
                            color: '#797979',
                            fontSize: '14px',
                            padding: '4px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                          },
                        }}
                      />
                      <Typography sx={Styles.validationText}>
                        {errors.password?.message}
                      </Typography>
                    </Box>

                    <Button
                      type="submit"
                      sx={{
                        ...Styles.signInBtn,
                        mt: { xl: 2, lg: 2, md: 1, sm: 0.5, xs: 0.5 },
                        p: { xl: 1, lg: 1, md: 1, sm: 0.5, xs: 0.5 },
                      }}
                    >
                      {SignUpLoading ? (
                        <ClipLoader color="inherit" size={20} />
                      ) : (
                        <Typography>Join now</Typography>
                      )}
                    </Button>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'center',
                      }}
                    >
                      <Box sx={Styles.regButton} onClick={login}>
                        <Box
                          component={'img'}
                          src={gglreg}
                          alt=""
                          sx={{ height: 'auto', width: '20px' }}
                        />
                        &nbsp;Login with Google
                      </Box>
                    </Box>
                  </Box>
                </form>
              ) : (
                <form onSubmit={SignUpSubmitForStudent}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexDirection: 'column',
                      width: {
                        xl: '80%',
                        lg: '80%',
                        md: '80%',
                        xs: '95%',
                        sm: '95%',
                      },
                      mx: 'auto',
                      gap: {
                        xl: '5px',
                        lg: '5px',
                        md: '5px',
                        sm: '5px',
                        xs: '5px',
                      },
                    }}
                  >
                    <Typography sx={Styles.subText}>Full Name</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        mx: 'auto',
                      }}
                    >
                      <Input
                        id="standard-basic"
                        variant="outlined"
                        disableUnderline
                        placeholder="Rahul Sharma"
                        {...registerForstudent('fullName')}
                        sx={{
                          ...Styles.InputFieldDes,
                          borderColor: isInputFocused
                            ? 'primary.main'
                            : '#E0E0E0',
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <Box
                              component="img"
                              src={user}
                              sx={Styles.iconStyle}
                            />
                          </InputAdornment>
                        }
                        inputProps={{
                          style: {
                            color: '#797979',
                            fontSize: '14px',
                            padding: '4px',
                            paddingLeft: '10px', // Adjust the left padding to make room for the icon
                          },
                        }}
                      />
                      <Typography sx={Styles.validationText}>
                        {errorsForStudent.fullName?.message}
                      </Typography>
                    </Box>
                    <Typography sx={Styles.subText}>Email</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        mx: 'auto',
                      }}
                    >
                      <Input
                        id="standard-basic"
                        variant="outlined"
                        disableUnderline
                        {...registerForstudent('email')}
                        placeholder="Email"
                        sx={Styles.InputFieldDes}
                        startAdornment={
                          <InputAdornment position="start">
                            <Box
                              component="img"
                              src={sms}
                              sx={Styles.iconStyle}
                            />
                          </InputAdornment>
                        }
                        inputProps={{
                          style: {
                            color: '#797979',
                            fontSize: '14px',
                            padding: '4px',
                            paddingLeft: '10px', // Adjust the left padding to make room for the icon
                          },
                        }}
                      />
                      <Typography sx={Styles.validationText}>
                        {errorsForStudent.email?.message}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        gap: '20px',
                        justifyContent: 'center',
                      }}
                    >
                      <Box>
                        <Typography sx={Styles.subText}>Mobile </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            mx: 'auto',
                            // mt: 0.5,
                          }}
                        >
                          <Input
                            id="standard-basic"
                            variant="outlined"
                            disableUnderline
                            {...registerForstudent('mobile')}
                            placeholder="8989836453"
                            sx={Styles.InputFieldDes}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box
                                  component="img"
                                  src={num}
                                  sx={{ ...Styles.iconStyle, width: '25px' }}
                                />
                              </InputAdornment>
                            }
                            inputProps={{
                              style: {
                                color: '#797979',
                                fontSize: '14px',
                                padding: '7px',
                                paddingLeft: '10px', // Adjust the left padding to make room for the icon
                              },
                            }}
                          />
                        </Box>
                        <Typography sx={Styles.validationText}>
                          {errorsForStudent.mobile?.message}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={Styles.subText}>City </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            mx: 'auto',
                            mt: 0.2,
                          }}
                        >
                          <Input
                            id="standard-basic"
                            variant="outlined"
                            disableUnderline
                            {...registerForstudent('city')}
                            placeholder="City"
                            sx={Styles.InputFieldDes}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box
                                  component="img"
                                  src={cityimg}
                                  sx={Styles.iconStyle}
                                />
                              </InputAdornment>
                            }
                            inputProps={{
                              style: {
                                color: '#797979',
                                fontSize: '14px',
                                padding: '4px',
                                paddingLeft: '10px', // Adjust the left padding to make room for the icon
                              },
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Typography sx={Styles.subText}>
                        Select Collage
                      </Typography>
                      <Select
                        sx={{ ...Styles.opt, width: '165px' }}
                        value={ipadd}
                        {...registerForstudent('ipaddress')}
                        onChange={handleIPaddChange}
                        // sx={{ width: '100%' }}
                      >
                        {colleges.map((college) => (
                          <MenuItem key={college} value={college}>
                            {college}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Box sx={{}}>
                      <label
                        htmlFor="fileInput"
                        style={{
                          fontWeight: 600,
                          cursor: 'pointer',
                        }}
                      >
                        <Box
                          sx={{
                            width: '97%',
                            color: 'rgba(115, 115, 115, 1)',
                            // border: '1.5px dashed  rgba(209, 209, 209, 1)',
                            border: '2px dashed rgba(209, 209, 209, 1)',
                            height: 'auto',
                            p: 0.5,
                            borderRadius: '10px',
                          }}
                          // onDragOver={handleDragOverMultiple}
                          // onDrop={handleDropMultiple}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              alignContent: 'center',
                              // gap: 1,
                              mt: 0.5,
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*,application/pdf"
                              id="fileInput"
                              style={{ display: 'none' }}
                              {...registerForstudent('proof')}
                              // onChange={(e}
                            />
                            <Typography>Upload Id Proof</Typography>
                          </Box>
                        </Box>
                        <Box mt={0}>
                          {fileNames.length > 0 && (
                            <Typography variant="body2">
                              {fileNames.join(', ')}
                            </Typography>
                          )}
                        </Box>
                      </label>
                    </Box>
                    <Typography sx={Styles.subText}>Choose Password</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        mx: 'auto',
                      }}
                    >
                      <Input
                        id="standard-basic"
                        variant="outlined"
                        disableUnderline
                        type={showPassword ? 'text' : 'password'}
                        {...registerForstudent('password')}
                        placeholder="Minimum 8 characters"
                        sx={Styles.InputFieldDes}
                        startAdornment={
                          <InputAdornment position="start">
                            <Box
                              component="img"
                              src={lock}
                              sx={Styles.iconStyle}
                            />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPasswordClick}>
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{
                          style: {
                            color: '#797979',
                            fontSize: '14px',
                            padding: '4px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                          },
                        }}
                      />
                      <Typography sx={Styles.validationText}>
                        {errorsForStudent.password?.message}
                      </Typography>
                    </Box>
                    <Button
                      type="submit"
                      sx={{
                        ...Styles.signInBtn,
                        mt: { xl: 1, lg: 1, md: 1, sm: 0.5, xs: 0.5 },
                        p: { xl: 1, lg: 1, md: 1, sm: 0.5, xs: 0.5 },
                      }}
                    >
                      {SignUpLoading ? (
                        <ClipLoader color="inherit" size={20} />
                      ) : (
                        <Typography>Join now</Typography>
                      )}
                    </Button>
                  </Box>
                </form>
              )}
            </Paper>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SignUp;

const headerStyle = {
  alignItems: 'center',
  height: '80px',
  transition: 'right 2s',
  color: 'white',
  fontSize: '30px',
  fontWeight: 'bold',
  fontFamily: 'Poppins',
  width: '80%',
  mx: 'auto',
  position: 'absolute',
  top: 0,
};
